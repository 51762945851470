var _a, _b;
import { createApp, watch } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import router from "@/router";
import VueApexCharts from "vue3-apexcharts";
import { placeholderToast } from "@svonk/util";
import { _status } from "@/common";
import { Toast } from "@svonk/util";
import "./registerServiceWorker";
import VueLazyload from "vue-lazyload";
import { useMainStore } from "@/store";
import { useShortcuts } from "@/store/shortcuts";
import { useMagic } from "@/store/magic";
import { useNotifications } from "@/store/notifications";
window.toast = placeholderToast;
window.$ = $;
// create instances of app requisites
const pinia = createPinia();
// TODO: TS | figure out what the heck is going on with this - why is App already initialized and existent with no new changes?
// if app exists, use it, otherwise create a new one
const app = createApp(App) || createApp(() => App);
// setup app requisites
app.use(router);
app.use(pinia);
const store = useMainStore(), shortcuts = useShortcuts(), magic = useMagic(), notify = useNotifications();
app.mixin({
    computed: {
        $env() {
            return process.env;
        },
        $status() {
            return _status;
        },
        $store() {
            return store;
        },
        $shortcuts() {
            return shortcuts;
        },
        $magic() {
            return magic;
        },
        $notify() {
            return notify;
        },
    },
});
app.use(VueApexCharts);
//* setup image loading (will use the classes it assigns to style loading images)
app.use(VueLazyload, {
    preLoad: 2,
    attempt: 1,
});
// mount app
app.mount("#root");
// setup store persistence using watcher
watch(pinia.state, (state) => {
    window.localStorage.setItem(`${process.env.VUE_APP_BRAND_NAME_SHORT}_app_state`, JSON.stringify(state.main));
}, { deep: true });
// when window.localStorage. _teacher_mode changes, reload
window.addEventListener("storage", (e) => {
    if (e.key === `${process.env.VUE_APP_BRAND_NAME_SHORT}_teacher_mode`) {
        _status.log("🏫 Detected teacher mode change, reloading");
        window.location.reload();
    }
});
// setup connectin listeners
_status.log("🛜 Started " + (((_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.onLine) ? "online" : "offline"));
if (!((_b = window === null || window === void 0 ? void 0 : window.navigator) === null || _b === void 0 ? void 0 : _b.onLine)) {
    new Toast("Features may not work as intended offline", "default", 1e4, require("@/assets/img/general/toast/connection-warn.svg"));
}
window.addEventListener("offline", () => {
    new Toast("Connection lost", "default", 3500, require("@/assets/img/general/toast/connection-error.svg"));
    _status.warn("🛜 Connection lost");
});
window.addEventListener("online", () => {
    new Toast("You're back online!", "default", 3500, require("@/assets/img/general/toast/connection-success.svg"));
    _status.log("🛜 Connection restored");
});
// styles
import "@/assets/style/main.css";
// import jquery and store
import $ from "jquery";
// auth
$(document.body).on("click", ".auth-action", function () {
    const store = useMainStore();
    if (!store.user && $(this).hasClass("can-login")) {
        store.login();
    }
    else if ($(this).hasClass("can-logout")) {
        if ($(this).hasClass("doprompt")) {
            store.logout_prompt = true;
        }
        else {
            store.logout();
        }
    }
});
$(document.body).on("click", ".gohome", function () {
    router.push("/");
});
// page change
import { removePopup } from "@svonk/util";
router.afterEach((to) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (to.meta && to.meta.page_title) {
        document.title = `${process.env.VUE_APP_BRAND_NAME_LONG} | ` + to.meta.page_title;
    }
    else {
        document.title = process.env.VUE_APP_BRAND_NAME_LONG;
    }
    let theme_light = ((_b = (_a = to.meta) === null || _a === void 0 ? void 0 : _a.theme_color) === null || _b === void 0 ? void 0 : _b.light) || `#${process.env.VUE_APP_THEME_COLOR}`;
    let theme_dark = ((_d = (_c = to.meta) === null || _c === void 0 ? void 0 : _c.theme_color) === null || _d === void 0 ? void 0 : _d.dark) || `#${process.env.VUE_APP_THEME_COLOR}`;
    $("meta.theme-color").attr("content", theme_light);
    $("meta.theme-color-dark").attr("content", theme_dark);
    // remove popup
    removePopup();
    // setup color variables for css
    const style = document.documentElement.style;
    style.setProperty("--theme-color", theme_light);
    style.setProperty("--theme-color-dark", theme_dark);
    // colors on theme color
    let text_light = ((_f = (_e = to.meta) === null || _e === void 0 ? void 0 : _e.theme_color) === null || _f === void 0 ? void 0 : _f.text_light) || "#000000";
    let text_dark = ((_h = (_g = to.meta) === null || _g === void 0 ? void 0 : _g.theme_color) === null || _h === void 0 ? void 0 : _h.text_dark) || "#ffffff";
    style.setProperty("--theme-color-text", text_light);
    style.setProperty("--theme-color-text-dark", text_dark);
    style.setProperty("--theme-color-hover", text_light + "33");
    style.setProperty("--theme-color-hover-dark", text_dark + "33");
});
// router guard
router.beforeEach((to) => {
    const store = useMainStore();
    if (to.meta && to.meta.requiresAuth && store && !store.user) {
        // launch auth popup through store action
        new Toast("Please log in or join to access this page", "default", 1500, require("@svonk/util/assets/info-locked-icon.svg"));
        // set redirect
        return {
            path: "/",
            query: { redirect: to.fullPath },
        };
    }
    else if (to.meta && to.meta.requiresTeacher && store.user && !store.is_teacher) {
        new Toast("You must be a teacher to access this page", "default", 1500, require("@svonk/util/assets/info-locked-icon.svg"));
        return { name: "portal" };
    }
    else if (to.meta && to.meta.requiresAdmin && store.user && !store.is_admin) {
        new Toast("Only administrators can access this page", "default", 1500, require("@svonk/util/assets/info-locked-icon.svg"));
        return { name: "portal" };
    }
});
